<template>
  <v-container class="ma-0 pa-0" fluid fill-height>
    <v-card
      elevation="0"
      class="ma-0 pa-0 mb-3"
      v-for="r in 15"
      :key="r"
      width="100%"
    >
      <v-row class="ma-0 pa-1" justify="center" align="center">
        <v-col cols="4" justify="center" align="center">
          <v-row>
            <v-col cols="1">
              <v-skeleton-loader type="text"> </v-skeleton-loader>
            </v-col>
            <v-col cols="2">
              <v-skeleton-loader type="text"> </v-skeleton-loader>
            </v-col>
            <v-col cols="3" v-for="c in 3" :key="c">
              <v-skeleton-loader type="text"> </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8" justify="center" align="center">
          <v-row>
            <v-col cols="2" v-for="c in 6" :key="c">
              <v-skeleton-loader type="text"> </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default { name: "SkeletonTableLead" };
</script>
