<template>
  <v-card width="100vw" height="90vh" elevation="0">
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" justify="center" align="center">
          <div class="title">{{ title }}</div>
        </v-col>
        <v-col cols="12" justify="center" align="center">
          <div class="subtitle-2 grey--text">{{ message }}</div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ErrorViewTableLead",
  props: {
    title: {
      type: String,
      default: "Vaya, parece que no hay Leads por mostrar."
    },
    message: {
      type: String,
      default: "No se encontraron Leads, intenta recargando la pagina."
    }
  }
};
</script>
