<template>
  <v-menu content-class="pa-2" :close-on-content-click="false" offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        elevation
        outlined
        rounded
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dense>mdi-view-column-outline</v-icon>
        Columnas
      </v-btn>
    </template>
    <v-checkbox
      v-for="item in items"
      :key="item.value"
      v-model="selected"
      :disabled="item.sticky"
      :value="item.value"
      :label="item.text"
      hide-details
      class="pa-0 my-1"
    />
  </v-menu>
</template>

<script>
import { HEADERS_TABLE } from "../../../../constants/tableHeaders";
import { mapState, mapActions } from "vuex";

export default {
  name: "HeadersConfig",
  computed: {
    ...mapState("leads/headers", ["headers"]),
    items() {
      return HEADERS_TABLE.map(({ text, value, sticky }) => ({
        text,
        value,
        sticky
      }));
    },
    selected: {
      get() {
        return this.headers;
      },
      set(value) {
        this.updateHeaders(value);
      }
    }
  },
  methods: {
    ...mapActions("leads/headers", ["updateHeaders"])
  }
};
</script>
